import axios from '@axios'
import store from '@/store'

export default {
  async getTipoData(token, id) {
    const response = await axios({
      method: 'get',
      url: `${store.state.app.api}/reportes/reporteLista`,
      timeout: `${1000 * store.state.app.timeout}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      params: {
        userId: id,
      },
    })
    return response
  },
  async getDescargarData(token, data) {
    const response = await axios({
      method: 'get',
      url: `${store.state.app.api}/reportes`,
      timeout: `${1000 * store.state.app.timeout}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      params: {
        userId: data.id,
        reporteName: data.reporte,
        fechaIni: data.inicial,
        fechaFin: data.final,
      },
    })
    return response
  },
}
