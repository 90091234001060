<template>
  <div>
    <b-row>
      <b-col cols="12">
        <b-card title="Lista de empresas">
          <div class="d-flex justify-content-between flex-wrap my-2">
            <!-- filter -->
            <b-form-group
              label="Buscador"
              label-cols-sm="3"
              label-align-sm="left"
              label-for="filterInput"
              class="mb-0"
            >
              <b-input-group size="sm">
                <b-form-input
                  id="filterInput"
                  v-model="filter"
                  placeholder="Buscador"
                  type="search"
                />
                <b-input-group-append>
                  <b-button
                    :disabled="!filter"
                    @click="filter = ''"
                  >
                    Limpiar
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </div>
          <b-table
            responsive
            hover
            show-empty
            bordered
            :small="true"
            :items="itemsEmpresa"
            :fields="fieldsEmpresa"
            :filter="filter"
            :filter-included-fields="filterOn"
            empty-filtered-text="No se encontro ningun resultado"
            empty-text=" "
            sticky-header="200px"
            select-mode="single"
            selectable
            @row-selected="onEmpresaSelect"
          >
            <template #empty>
              <h4 class="text-center my-2">
                No tiene información para mostrar
              </h4>
            </template>
            <template #table-busy>
              <div class="text-center my-2">
                <b-spinner class="align-middle" />
                <strong class="ml-1">Cargando ...</strong>
              </div>
            </template>
            <template #head()="scope">
              <div class="text-nowrap">
                {{ scope.label }}
              </div>
            </template>
            <template #cell()="data">
              <span class="text-nowrap">{{ data.value }}</span>
            </template>
            <template #cell(acciones)="{ rowSelected }">
              <template v-if="rowSelected">
                <span aria-hidden="true">&check;</span>
                <span class="sr-only">Selected</span>
              </template>
              <template v-else>
                <span aria-hidden="true">&nbsp;</span>
                <span class="sr-only">Not selected</span>
              </template>
            </template>
          </b-table>
        </b-card>
      </b-col>
    </b-row>
    <b-row v-if="idEmpresa > 0">
      <b-col cols="12">
        <b-card>
          <validation-observer ref="filterReporte">
            <b-row>
              <b-col
                md="6"
                xs="12"
              >
                <b-form-group
                  label-for="reporte"
                  label="Reporte"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="reporte"
                    rules="required"
                  >
                    <b-form-select
                      id="reporte"
                      v-model="selectedReporte"
                      name="reporte"
                      :options="optionsReporte"
                      :state="errors.length > 0 ? false : null"
                    />
                    <br>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                md="6"
                xs="12"
              >
                <b-form-group
                  label-for="inicio"
                  label="Fecha inicio"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="fecha inicio"
                    rules="required"
                  >
                    <b-form-datepicker
                      v-model="inicio"
                      selected-variant="primary"
                      :max="max"
                      :hide-header="true"
                      placeholder="Seleccionar fecha"
                      locale="es"
                    />
                    <br>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                md="6"
                xs="12"
              >
                <b-form-group
                  label-for="final"
                  label="Fecha final"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="fecha final"
                    rules="required"
                  >
                    <b-form-datepicker
                      v-model="final"
                      selected-variant="primary"
                      :max="max"
                      :hide-header="true"
                      placeholder="Seleccionar fecha"
                      locale="es"
                    />
                    <br>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <div class="d-flex align-items-center justify-content-end my-2">
                  <b-button
                    variant="primary"
                    class="ml-1"
                    @click="descargarReporte()"
                  >
                    <feather-icon
                      icon="DownloadIcon"
                      class="mr-50 text-white"
                    />
                    <span class="text-white">Descargar reporte</span>
                  </b-button>
                  <b-button
                    variant="danger"
                    class="ml-1"
                    @click="limpiarReporte()"
                  >
                    <feather-icon
                      icon="FileIcon"
                      class="mr-50"
                    />
                    Limpiar
                  </b-button>
                </div>
              </b-col>
            </b-row>
          </validation-observer>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BFormGroup,
  BTable,
  BButton,
  BFormSelect,
  BFormDatepicker,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
} from 'bootstrap-vue'

import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { required } from '@validations'
// eslint-disable-next-line import/no-cycle
import empresaService from '@/service/empresaService'
// eslint-disable-next-line import/no-cycle
import reporteService from '@/service/reporteService'
import xlsx from 'json-as-xlsx'

export default {
  components: {
    BTable,
    BRow,
    BCol,
    BCard,
    BFormGroup,
    BButton,
    BFormSelect,
    BFormDatepicker,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
  },
  data() {
    const now = new Date()
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
    const minDate = new Date(today)
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      token: localStorage.getItem('token'),
      required,
      selectedReporte: null,
      optionsReporte: [{ value: null, text: 'Seleccionar reporte' }],
      inicio: null,
      final: null,
      max: minDate,
      idEmpresa: 0,
      fieldsEmpresa: [
        {
          key: 'id',
          thClass: 'd-none',
          tdClass: 'd-none',
        },
        {
          label: 'RUC',
          key: 'ruc',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-left',
        },
        {
          label: 'Empresa',
          key: 'name',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-left',
        },
        {
          label: 'Pais',
          key: 'pais',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-left',
        },
        {
          label: 'Contacto',
          key: 'personacontacto',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-left',
        },
        {
          label: 'Teléfono',
          key: 'telfcontacto',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-left',
        },
        {
          label: 'Selección',
          key: 'acciones',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
      ],
      itemsEmpresa: [],
      filter: null,
      filterOn: [],
    }
  },
  mounted() {
    this.$nextTick(async () => {
      this.$store.commit('app/UPDATE_ISLOADING', true)
      await this.obtenerListaEmpresa()
      this.$store.commit('app/UPDATE_ISLOADING', false)
    })
  },
  methods: {
    async obtenerListaEmpresa() {
      this.$store.commit('app/UPDATE_ISLOADING', true)
      const responseEmpresa = await empresaService.postEmpresaData(this.token, this.userData.id)
      if (responseEmpresa.data.data.length > 0) {
        this.itemsEmpresa = responseEmpresa.data.data
      }
      this.$store.commit('app/UPDATE_ISLOADING', false)
    },
    async onEmpresaSelect(items) {
      this.$store.commit('app/UPDATE_MESSAGE', 'Obteniendo informacion de la empresa.')
      this.$store.commit('app/UPDATE_ISLOADING', true)
      this.idEmpresa = items[0].id
      await this.obtenerTipo()
      this.$store.commit('app/UPDATE_ISLOADING', false)
    },
    async obtenerTipo() {
      this.$store.commit('app/UPDATE_ISLOADING', true)
      const responseTipo = await reporteService.getTipoData(
        this.token,
        this.userData.id,
      )
      const { data } = responseTipo.data
      if (data.length > 0) {
        this.optionsReporte = [{ value: null, text: 'Seleccionar reporte' }]
        data.forEach(element => this.optionsReporte.push({
          value: element.id,
          text: element.nombre,
        }))
      }
      this.$store.commit('app/UPDATE_ISLOADING', false)
    },
    async descargarReporte() {
      const success = await this.$refs.filterReporte.validate()
      if (success) {
        this.$store.commit('app/UPDATE_ISLOADING', true)
        const day1 = new Date(this.inicio)
        const day2 = new Date(this.final)

        const difference = Math.abs(day2 - day1)
        const days = difference / (1000 * 3600 * 24)

        if (days <= 31) {
          const form = {
            id: this.userData.id,
            reporte: this.selectedReporte,
            inicial: this.inicio,
            final: this.final,
          }
          const response = await reporteService.getDescargarData(
            this.token,
            form,
          )
          const { data } = response.data
          if (data.length > 0) {
            const fecha = new Date()
            const añoActual = fecha.getFullYear()
            const mesActual = fecha.getMonth() + 1
            const diaActual = fecha.getDate()

            const reporte = this.optionsReporte.find(element => element.value === this.selectedReporte)
            const titulo = `${reporte.text.toLowerCase().replace(' ', '-')}-${diaActual}-${mesActual}-${añoActual}`
            const settings = {
              fileName: titulo, // Name of the resulting spreadsheet
              extraLength: 3, // A bigger number means that columns will be wider
              writeMode: 'writeFile', // The available parameters are 'WriteFile' and 'write'. This setting is optional. Useful in such cases https://docs.sheetjs.com/docs/solutions/output#example-remote-file
              writeOptions: {}, // Style options from https://docs.sheetjs.com/docs/api/write-options
              RTL: false, // Display the columns from right-to-left (the default value is false)
            }
            const column = []
            Object.keys(data[0]).forEach(element => {
              column.push(
                { label: element, value: element },
              )
            })
            const dataFile = [
              {
                sheet: 'detalle',
                columns: column,
                content: data,
              },
            ]
            xlsx(dataFile, settings)
          } else {
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: 'Notificación',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                  text: 'No tiene información para descargar.',
                },
              },
              {
                position: 'bottom-center',
                closeOnClick: true,
              },
            )
          }
        } else {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Notificación',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
                text: 'El rango de data a descargar no debe ser mayor a 31 dias.',
              },
            },
            {
              position: 'bottom-center',
              closeOnClick: true,
            },
          )
        }
        this.$store.commit('app/UPDATE_ISLOADING', false)
      }
    },
    async limpiarReporte() {
      this.$refs.filterReporte.reset()
      this.selectedReporte = null
      this.inicio = null
      this.final = null
    },
  },
}
</script>

<style></style>
