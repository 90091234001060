var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-card',{attrs:{"title":"Lista de empresas"}},[_c('div',{staticClass:"d-flex justify-content-between flex-wrap my-2"},[_c('b-form-group',{staticClass:"mb-0",attrs:{"label":"Buscador","label-cols-sm":"3","label-align-sm":"left","label-for":"filterInput"}},[_c('b-input-group',{attrs:{"size":"sm"}},[_c('b-form-input',{attrs:{"id":"filterInput","placeholder":"Buscador","type":"search"},model:{value:(_vm.filter),callback:function ($$v) {_vm.filter=$$v},expression:"filter"}}),_c('b-input-group-append',[_c('b-button',{attrs:{"disabled":!_vm.filter},on:{"click":function($event){_vm.filter = ''}}},[_vm._v(" Limpiar ")])],1)],1)],1)],1),_c('b-table',{attrs:{"responsive":"","hover":"","show-empty":"","bordered":"","small":true,"items":_vm.itemsEmpresa,"fields":_vm.fieldsEmpresa,"filter":_vm.filter,"filter-included-fields":_vm.filterOn,"empty-filtered-text":"No se encontro ningun resultado","empty-text":" ","sticky-header":"200px","select-mode":"single","selectable":""},on:{"row-selected":_vm.onEmpresaSelect},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_c('h4',{staticClass:"text-center my-2"},[_vm._v(" No tiene información para mostrar ")])]},proxy:true},{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center my-2"},[_c('b-spinner',{staticClass:"align-middle"}),_c('strong',{staticClass:"ml-1"},[_vm._v("Cargando ...")])],1)]},proxy:true},{key:"head()",fn:function(scope){return [_c('div',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(scope.label)+" ")])]}},{key:"cell()",fn:function(data){return [_c('span',{staticClass:"text-nowrap"},[_vm._v(_vm._s(data.value))])]}},{key:"cell(acciones)",fn:function(ref){
var rowSelected = ref.rowSelected;
return [(rowSelected)?[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("✓")]),_c('span',{staticClass:"sr-only"},[_vm._v("Selected")])]:[_c('span',{attrs:{"aria-hidden":"true"}}),_c('span',{staticClass:"sr-only"},[_vm._v("Not selected")])]]}}])})],1)],1)],1),(_vm.idEmpresa > 0)?_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-card',[_c('validation-observer',{ref:"filterReporte"},[_c('b-row',[_c('b-col',{attrs:{"md":"6","xs":"12"}},[_c('b-form-group',{attrs:{"label-for":"reporte","label":"Reporte"}},[_c('validation-provider',{attrs:{"name":"reporte","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"id":"reporte","name":"reporte","options":_vm.optionsReporte,"state":errors.length > 0 ? false : null},model:{value:(_vm.selectedReporte),callback:function ($$v) {_vm.selectedReporte=$$v},expression:"selectedReporte"}}),_c('br'),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2416025016)})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"6","xs":"12"}},[_c('b-form-group',{attrs:{"label-for":"inicio","label":"Fecha inicio"}},[_c('validation-provider',{attrs:{"name":"fecha inicio","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-datepicker',{attrs:{"selected-variant":"primary","max":_vm.max,"hide-header":true,"placeholder":"Seleccionar fecha","locale":"es"},model:{value:(_vm.inicio),callback:function ($$v) {_vm.inicio=$$v},expression:"inicio"}}),_c('br'),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3985209652)})],1)],1),_c('b-col',{attrs:{"md":"6","xs":"12"}},[_c('b-form-group',{attrs:{"label-for":"final","label":"Fecha final"}},[_c('validation-provider',{attrs:{"name":"fecha final","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-datepicker',{attrs:{"selected-variant":"primary","max":_vm.max,"hide-header":true,"placeholder":"Seleccionar fecha","locale":"es"},model:{value:(_vm.final),callback:function ($$v) {_vm.final=$$v},expression:"final"}}),_c('br'),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1611323859)})],1)],1)],1),_c('b-row',[_c('b-col',[_c('div',{staticClass:"d-flex align-items-center justify-content-end my-2"},[_c('b-button',{staticClass:"ml-1",attrs:{"variant":"primary"},on:{"click":function($event){return _vm.descargarReporte()}}},[_c('feather-icon',{staticClass:"mr-50 text-white",attrs:{"icon":"DownloadIcon"}}),_c('span',{staticClass:"text-white"},[_vm._v("Descargar reporte")])],1),_c('b-button',{staticClass:"ml-1",attrs:{"variant":"danger"},on:{"click":function($event){return _vm.limpiarReporte()}}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"FileIcon"}}),_vm._v(" Limpiar ")],1)],1)])],1)],1)],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }